<template>
    <div class="container">
        <div
            class="alert alert-warning text-start mt-2"
            v-if="errorMessage"
            role="alert"
        >
            <span v-html="errorMessage" />
        </div>
        <div :class="['text-center', errorMessage ? 'mt-2' : 'mt-8']">
            <img
                :src="require(`../assets/images/pages/activity/bank-note.svg`)"
                :width="160"
                :class="[tipSucceeded ? 'mt-4' : 'mt-0', 'mb-4']"
                alt="Bank Note"
            >
        </div>
        <div v-if="tipSucceeded && tipAmount > 0">
            <h5 class="text-center fw-light mx-2 mb-5">
                {{ $t('pages.payItForwardTipping.tippedSourceMessage') }}
            </h5>
        </div>
        <div v-else>
            <div
                class="text-center"
                v-if="!showAmountEntry"
            >
                <h5
                    class="text-center fw-light mx-2 mb-5"
                    v-html="$t('pages.payItForwardTipping.description', { sourceName: payItForwardSourceName, maxAmount: maxAmountInUSD, quarterOfMaxAmountTip, tipAmount: quarterOfMaxAmountInUSD })"
                />
                <base-button
                    class="mb-2"
                    :submitting="submittingTip && tipAmount === quarterOfMaxAmountTip"
                    @click="processTip(75)"
                >
                    Share {{ quarterOfMaxAmountInUSD }}
                </base-button>
                <div class="other-options mb-2">
                    <div v-if="submittingTip && (false === [quarterOfMaxAmountTip].includes(tipAmount) || tipAmount === 0)">
                        <span class="spinner-border spinner-border-sm" />
                    </div>
                    <div v-else>
                        <button @click="otherAmount">
                            {{ $t('pages.payItForwardTipping.otherAmount') }}
                        </button>
                        &nbsp;&nbsp;|&nbsp;&nbsp;
                        <button @click="processTip(0)">
                            {{ $t('pages.payItForwardTipping.noTip') }}
                        </button>
                    </div>
                </div>
            </div>
            <div
                class="mb-2"
                v-else
            >
                <h5
                    class="text-center fw-light mx-2 mb-5"
                    v-html="$t('pages.payItForwardTipping.customTipDescription', { sourceName: payItForwardSourceName, maxAmount: maxAmountInUSD })"
                />
                <form-container
                    ref="customTipContainer"
                    @onSubmit="processCustomTip"
                >
                    <form-field-currency
                        ref="customTipField"
                        v-model="customTip"
                        name="customTip"
                        class="mb-2"
                        :validation="`currency:0,${this.payItForwardTipMaxAmount}`"
                        :options="customCurrencyInputOptions"
                        placeholder="Enter Amount"
                    />
                    <base-button
                        type="submit"
                        :disabled="!isCustomAmountValid"
                        :submitting="submittingTip"
                    >
                        {{ $t('pages.payItForwardTipping.cta') }}
                    </base-button>
                </form-container>
            </div>
            <div>
                <p
                    class="small text-muted text-center text-muted"
                    v-html="$t('pages.payItForwardTipping.terms')"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import BaseButton from '@/components/base/BaseButton'
    import format from '@/mixins/format'
    import { i18n } from '@/utils/i18n'
    import FormContainer from '@/components/base/FormContainer'
    import FormFieldCurrency from '@/components/base/FormFieldCurrency'
    import { mapGetters } from 'vuex'
    import { submitPayItForwardTip } from '@/services/avenAppApi'
    import { logger } from '@/utils/logger'
    import { RouteNames } from '@/routes/router'
    import { navigation } from '@/mixins/navigation'

    export default {
        name: RouteNames.PAY_IT_FORWARD_SEND_TIP,
        components: {
            BaseButton,
            FormFieldCurrency,
            FormContainer,
        },
        mixins: [format, navigation],
        computed: {
            ...mapGetters(['isWebView', 'payItForwardTipMaxAmount', 'payItForwardSourceName', 'isEligibleToPerformPayItForwardTipping']),
            quarterOfMaxAmountTip: function () {
                return this.payItForwardTipMaxAmount * 0.25
            },
            maxAmountInUSD: function () {
                return this.toFormattedUSDNoCents(this.payItForwardTipMaxAmount)
            },
            quarterOfMaxAmountInUSD: function () {
                return this.toFormattedUSDNoCents(this.payItForwardTipMaxAmount * 0.25)
            },
            isCustomAmountValid: function () {
                const amountEntered = parseFloat(this.customTip)
                if (isNaN(amountEntered)) {
                    return false
                }
                return amountEntered <= this.payItForwardTipMaxAmount
            },
        },
        data: function () {
            return {
                loading: false,
                // payItForward data
                errorMessage: null,
                submittingTip: false,
                showAmountEntry: false,
                customTip: '',
                tipAmount: null,
                customCurrencyInputOptions: {
                    numeral: true,
                    prefix: '$',
                    noImmediatePrefix: true,
                    rawValueTrimPrefix: true,
                    numeralPositiveOnly: true,
                },
                tipSucceeded: false,
            }
        },
        mounted() {
            // in case someone tries to manually enter the path and they are not eligible.
            if (!this.isEligibleToPerformPayItForwardTipping) {
                this.returnToRoot()
            }
            this.$logEvent('view_pay_it_forward_tipping')
        },
        methods: {
            processCustomTip: async function () {
                if (this.isCustomAmountValid) {
                    await this.processTip(parseFloat(this.customTip))
                }
            },
            processTip: async function (value) {
                logger.info(`process tip ${value}`)
                if (this.submittingTip || value > this.payItForwardTipMaxAmount) {
                    return
                }
                this.errorMessage = null
                this.submittingTip = true
                this.tipAmount = value

                this.$logEvent('click_button_pif_tip', { tipAmount: this.tipAmount })
                try {
                    const response = await submitPayItForwardTip(this.tipAmount)
                    const responseData = response.data
                    this.tipSucceeded = responseData.success

                    if (this.tipSucceeded && this.tipAmount > 0) {
                        setTimeout(() => {
                            logger.info(`Tip was set, replace current route with ${this.currentRootPath}`)
                            this.returnToRoot()
                        }, 2000)
                    } else {
                        // responseData.success is false due to either ERROR_PIF_TIP_ALREADY_SET or ERROR_PIF_INELIGIBLE
                        // in either case we move on without showing error.
                        logger.info(`Tip was not set, ${JSON.stringify(responseData)}, replace with ${this.currentRootPath}`)
                        await this.returnToRoot()
                    }
                } catch (e) {
                    this.errorMessage = i18n.t('global.errors.generic')
                } finally {
                    this.submittingTip = false
                }
            },
            otherAmount: function () {
                this.showAmountEntry = true
            },
        },
    }
</script>

<style lang="scss" scoped>
    .other-options {
        font-size: $font-size-base;
        color: $gray-400;
        display: flex;
        justify-content: center;
        align-items: center;
        height: $input-height;
        button {
            background: none;
            border: none;
            font-weight: $font-weight-normal;
            color: $gray-400;
            text-decoration: underline;
            &:first-child {
                margin-right: 4px;
            }
            &:last-child {
                margin-left: 4px;
            }
        }
    }
</style>
